'use client';

import * as Sentry from '@sentry/nextjs';
import fetchWithAuth from './fetch-with-auth.client';
import fetcher from './fetcher';
import intercept from './intercept';
import adminInterceptor from './interceptors/admin-interceptor';
import { GraphqlFetchConfig } from './types';

const requestInfo =
  process.env.GRAPHQL_URL ??
  process.env.NEXT_PUBLIC_GRAPHQL_URL ??
  'http://localhost:4001/graphql';

export async function graphqlFetch<TVariables>(
  config: GraphqlFetchConfig<TVariables>,
) {
  try {
    const { query, variables, withAuth = false, isAdmin } = config;
    const req = intercept({ query, variables }, { isAdmin: isAdmin ?? null })
      .process(adminInterceptor)
      .valueOf();
    if (!req?.query) {
      return { data: null };
    }
    const requestInit = {
      method: 'POST',
      body: JSON.stringify({
        query,
        variables: variables || {},
      }),
    };
    if (!withAuth) {
      return fetcher(undefined, requestInfo, requestInit);
    }
    return fetchWithAuth(query, variables);
  } catch (error) {
    console.log('error when fetching', error);
    Sentry.captureException(error);
  }
}

export default graphqlFetch;
