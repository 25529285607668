import { config } from 'src/config';

import { Route } from './entities/Route';
import { RoutesCollection } from './entities/RoutesCollection';

export const publicCollection = new RoutesCollection(
  {
    mode: 'public',
    index: true,
    sitemap: true,
    locales: [config.i18n.default],
  },
  [
    new Route('/', { locales: ['en', 'pl'] }),
    new Route('/about'),
    new Route('/faqs'),

    // DEMO
    new Route('/demo/dashboard'),
    new Route('/demo/dashboard/tenant-tree'),

    // AUTH ROUTES
    new Route('/signin', {
      locales: ['en', 'pl'],
    }),
    new Route('/create-credentials'),

    // LEGAL ROUTES
    new Route('/legal/cookies'),
    new Route('/legal/privacy-policy'),
    new Route('/legal/terms-and-conditions'),

    // ERRORs ROUTES
    new Route('/error/404', {
      index: false,
      sitemap: false,
      locales: ['en', 'pl'],
    }),
  ] as const,
);
