'use client';

import graphqlFetch from '@client/src/data-access/graphql-fetch.client';
import { SEND_WAITLIST_EMAIL } from '@client/src/queries/sendWaitlistEmail';
import { useTranslations } from 'next-intl';
import { useState, useTransition } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';
import { ActionInput } from './action-input';

interface EmailCTAProps {
  isSimple?: boolean;
}

export function EmailCTA({ isSimple = false }: EmailCTAProps) {
  const t = useTranslations('components.landing');
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [success, setSuccess] = useState(false);
  const [mutationError, setMutationError] = useState(false);

  const [isPending, startTransition] = useTransition();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ mode: 'onTouched' });

  const sendForm = (formData: any) => {
    startTransition(async () => {
      try {
        if (!executeRecaptcha) {
          console.error('ReCaptcha not loaded');
          return;
        }
        const recaptchaToken = await executeRecaptcha('waitlistRequest');

        const response = await graphqlFetch({
          query: SEND_WAITLIST_EMAIL,
          variables: {
            email: formData.email,
            recaptchaToken,
          },
        });
        if (response.data.sendWaitlistEmail) {
          setSuccess(response.data.sendWaitlistEmail);
        } else {
          setMutationError(true);
        }
      } catch (error) {
        console.error('Error in sendForm', error);
        setMutationError(true);
      }
    });
  };

  return (
    <>
      {success ? (
        <div className="text-green-600 font-semibold">
          {t('successMessage')}
        </div>
      ) : (
        <ActionInput
          inputType="email"
          placeholder={t('emailPlaceholder')}
          className="md:min-w-[640px]"
          actionClassName="!py-2"
          onSubmit={handleSubmit(sendForm)}
          isDisabled={isPending || success}
          error={
            (errors.email?.message ||
              (mutationError ? t('errorMessage') : '')) as string
          }
          actionLabel={
            isSimple ? (
              t('simpleActionLabel')
            ) : (
              <div className="flex flex-col gap-[2px]">
                <span className="!text-new-neutral-white !leading-none !text-[16px] !font-[700]">
                  {t('joinNow')}
                </span>
                <span className="!text-new-neutral-white !leading-[1.1] !text-[12px] !font-[400] max-w-[12ch]">
                  {t('keepDataSecure')}
                </span>
              </div>
            )
          }
          {...register('email', {
            required: t('emailRequired'),
            pattern: {
              value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i,
              message: t('invalidEmail'),
            },
          })}
        />
      )}
    </>
  );
}
