'use client';

import * as Sentry from '@sentry/nextjs';
import { getCookie } from 'cookies-next';

import { TOKENS } from 'src/const/auth';
import env from 'src/env';
import { isString } from 'src/types/type-guards';
import { verifyExpiry } from 'src/utils/token';
import TokenManager from './token-manager';

const { graphqlUrl, nextPublicApiTokens } = env;

interface GraphQLResponse<T = any> {
  data?: T;
  errors?: Array<{
    message: string;
    locations?: Array<{ line: number; column: number }>;
    path?: Array<string | number>;
  }>;
}

function isGraphQLAuthError(res: any) {
  return (
    res?.errors &&
    (res?.errors[0]?.message === 'Unauthenticated' ||
      res?.errors[0]?.message === 'UNAUTHORIZED')
  );
}

function isUnauthorized(response: Response) {
  return response.status === 401 || isGraphQLAuthError(response);
}

function extractAccessToken() {
  return getCookie(TOKENS.accessToken);
}

async function fetchAccessToken() {
  const response = await fetch(nextPublicApiTokens, {
    method: 'POST',
    credentials: 'include',
  });
  return response;
}

const tokenManager = new TokenManager(
  async () => {
    const res = await fetchAccessToken();

    if (res?.status === 401) {
      return { accessToken: null };
    }

    return { accessToken: extractAccessToken() };
  },
  { accessToken: extractAccessToken() },
);
async function fetchWithAuth<T = any>(
  query: string,
  variables?: { [key: string]: any },
): Promise<GraphQLResponse<T>> {
  let accessToken: string | null = extractAccessToken() as string;

  const isAccessTokenValid = isString(accessToken) && verifyExpiry(accessToken);

  if (!isAccessTokenValid) {
    try {
      await tokenManager.refreshTokens();
      accessToken = tokenManager.getAccessToken();
    } catch (error) {
      console.log('Error in client/src/data-access/fetch-with-auth.ts', error);
      Sentry.captureException(error);
      //  throw new Error('Unauthorized');
    }
  }

  const defaultHeaders = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
  };

  try {
    let response = await fetch(graphqlUrl, {
      method: 'POST',
      credentials: 'include',
      headers: defaultHeaders,
      body: JSON.stringify({
        query,
        variables,
      }),
    });
    if (isUnauthorized(response)) {
      // refresh and try again if unauthenticated
      await tokenManager.refreshTokens();
      accessToken = tokenManager.getAccessToken();

      response = await fetch(graphqlUrl, {
        method: 'POST',
        credentials: 'include',
        headers: {
          ...defaultHeaders,
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          query,
          variables,
        }),
      });
    }

    // Check for errors again after the second attempt

    if (isUnauthorized(response)) {
      window.location.replace('/signin?unauthenticated=true');
      console.error('Unauthenticated');
    }
    return response.json();
  } catch (err) {
    console.log('fetch with auth error', err);
    throw err;
  }
}

export default fetchWithAuth;
