import { Icon } from '@iconify/react';
import React, { forwardRef, memo } from 'react';

import { IconifyProps } from './types';

// ----------------------------------------------------------------------

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  icon: IconifyProps;
  width?: number;
}

const Iconify = forwardRef<HTMLDivElement, Props>(
  ({ icon, width = 20, className, ...other }, ref) => {
    const sizeClass = `w-[${width}px] h-[${width}px]`;
    
    return (
      <div
        ref={ref}
        className={`inline-block component-iconify ${sizeClass} ${className || ''}`}
        {...other}
      >
        <Icon icon={icon} className="w-full h-full" />
      </div>
    );
  }
);

export default memo(Iconify);
