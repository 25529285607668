'use client';

import { useLocale, useTranslations } from 'next-intl';
import { useCallback, useState } from 'react';
import Iconify from 'src/components/iconify';
import { config } from 'src/config';
import { usePathname, useRouter } from 'src/navigation';
import { AvailableLocales } from 'src/routes/types';

const LANGUAGES_ICONS = {
  en: 'flagpack:gb-nir',
  pl: 'flagpack:pl',
  de: 'flagpack:de',
};

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const allLocales = config.i18n.locales;
  const currentLocale = useLocale() as AvailableLocales[number];
  const t = useTranslations('components.language-selector');
  const router = useRouter();
  const pathname = usePathname();

  const [isOpen, setIsOpen] = useState(false);

  const handleChangeLang = useCallback(
    (newLocale: AvailableLocales[number]) => {
      router.push(pathname, { locale: newLocale });
      setIsOpen(false);
    },
    [allLocales],
  );

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`w-10 h-10 flex items-center justify-center rounded-full bg-transparent border-none transition-colors ${
          isOpen ? 'bg-gray-200' : 'hover:bg-gray-100'
        }`}
      >
        <Iconify
          icon={LANGUAGES_ICONS[currentLocale]}
          className="rounded-sm w-7"
        />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-40 bg-white rounded-md shadow-lg z-10">
          {allLocales.map((locale) => (
            <button
              key={locale}
              onClick={() => handleChangeLang(locale)}
              className={`w-full text-left px-4 py-2 flex items-center space-x-2 hover:bg-gray-100 border-none bg-neutral-50 ${
                locale === currentLocale ? 'bg-gray-100' : ''
              }`}
            >
              <Iconify
                icon={LANGUAGES_ICONS[locale]}
                className="rounded-sm w-7"
              />
              <span>{t(locale)}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
}
