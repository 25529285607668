function intercept(data: any, flags?: { [key: string]: boolean | null }) {
  let dataCopy = { ...data };
  return {
    process: (
      interceptor: (
        res: any,
        flags?: { [key: string]: boolean | null },
      ) => void,
    ) => {
      interceptor(dataCopy, flags);

      return intercept(dataCopy, flags);
    },
    valueOf: () => dataCopy,
  };
}

export default intercept;
