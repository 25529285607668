import { decodeJwt } from 'jose';
import { isJWTPayload } from '../utils/token';

type Tokens = {
  accessToken?: string | null;
  refreshToken?: string | null;
};

type InjectableTokenFetcher = () => Promise<Tokens | null>;

function getRefreshTokenTimeout(token: string) {
  const decodedToken = decodeJwt(token);
  if (!decodedToken || !isJWTPayload(decodedToken)) {
    return null;
  }
  const currentTime = Math.floor(Date.now() / 1000);
  const exp = decodedToken?.exp ?? 0;
  const targetTime = exp - 120;

  const waitTimeMs = (targetTime - currentTime) * 1000;
  return waitTimeMs;
}

class TokenManager {
  private refreshing: Promise<Tokens | null> | null = null;
  private tokens: Tokens | null = null;
  private prevTimeoutId: NodeJS.Timeout | null;
  tokenFetcher: InjectableTokenFetcher;

  constructor(tokenFetcher: InjectableTokenFetcher, initialTokens: Tokens) {
    this.refreshing = null;
    this.tokens = initialTokens;
    this.tokenFetcher = tokenFetcher;
    this.prevTimeoutId = null;
    this.scheduleRefreshTokenAction(initialTokens?.accessToken);
  }

  private async scheduleRefreshTokenAction(
    accessToken: string | undefined | null,
  ) {
    if (this.prevTimeoutId) {
      clearTimeout(this.prevTimeoutId);
    }
    if (!accessToken) {
      return null;
    }

    const timeout = getRefreshTokenTimeout(accessToken);

    if (timeout === null) {
      return;
    }

    if (timeout <= 0) {
      const newTokens = await this.refreshTokens();
      if (newTokens?.accessToken === accessToken) {
        return;
      }
      this.scheduleRefreshTokenAction(newTokens?.accessToken);
      return;
    }
    this.prevTimeoutId = setTimeout(async () => {
      const newTokens = await this.refreshTokens();
      if (newTokens?.accessToken === accessToken) {
        return;
      }
      this.scheduleRefreshTokenAction(newTokens?.accessToken);
    }, timeout);
  }

  getRefreshToken() {
    return this?.tokens?.refreshToken || null;
  }
  getAccessToken() {
    return this?.tokens?.accessToken || null;
  }
  async refreshTokens() {
    if (this.refreshing) {
      await this.refreshing;
    } else {
      this.refreshing = this._refreshTokens();
      await this.refreshing;
      this.refreshing = null;
    }
    return this.tokens;
  }
  async _refreshTokens() {
    const tokens = await this.tokenFetcher();

    this.tokens = tokens;
    return this.tokens;
  }
}

export default TokenManager;
