const RESTRICTED_QUERIES = [
  'getAppUsers',
  'getApps',
  'getApp',
  'getAppFilterCounts',
  'getAppPermissions',
  'getAppGroups',
];

function isQueryRestricted(query: string) {
  let isRestricted = false;
  RESTRICTED_QUERIES.forEach((restrictedQuery) => {
    if (query.includes(restrictedQuery)) {
      isRestricted = true;

      return;
    }
  });
  return isRestricted;
}

function adminInterceptor(req: any, flags?: { [key: string]: boolean | null }) {
  if (flags?.isAdmin || flags?.isAdmin === null /* unset */) {
    return;
  }
  if (
    typeof req.query === 'string' &&
    isQueryRestricted(req?.query) &&
    !flags?.isAdmin
  ) {
    req.query = null;
  }
}

export default adminInterceptor;
