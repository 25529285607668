import {
  createLocalizedPathnamesNavigation,
  Pathnames,
} from 'next-intl/navigation';

import { config } from './config';
import { protectedCollection } from './routes/protected';
import { publicCollection } from './routes/public';

export const localePrefix = undefined;
const locales = config.i18n.locales;

export const pathnames = {
  ...publicCollection.getAllLocalesPathnames_sync(),
  ...protectedCollection.getAllLocalesPathnames_sync(),
} satisfies Pathnames<typeof locales>;

export const { Link, redirect, usePathname, useRouter, getPathname } =
  createLocalizedPathnamesNavigation({ locales, localePrefix, pathnames });
