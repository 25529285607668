import { decodeJwt, JWTPayload } from 'jose';
import { NextRequestWithAuth } from 'next-auth/middleware';
import { NextRequest, NextResponse } from 'next/server';
import { TOKENS } from 'src/const/auth';

type Context = NextRequestWithAuth | NextResponse | NextRequest;

export const COOKIE_AGE = {
  refreshToken: 3 * 30 * 24 * 60 * 60 * 1000 + 1 * 60 * 1000,
  accessToken: 16 * 60 * 1000,
};

export function isJWTPayload(x: unknown): x is JWTPayload {
  return !!x && typeof x === 'object' && 'exp' in x;
}

export function verifyExpiry(token: string | null) {
  if (typeof token !== 'string') {
    return false;
  }
  const decoded = decodeJwt(token);
  if (typeof decoded !== 'object') {
    return false;
  }
  if (!isJWTPayload(decoded) || !decoded?.exp) {
    return false;
  }
  if (Date.now() >= decoded.exp * 1000) {
    return false;
  }
  return true;
}

export async function refreshTokenOnServer(
  refreshToken: string,
  // path = env.refreshTokenUrl  //process.env.REFRESH_TOKEN_URL ?? 'http://127.0.0.1:4001/refresh-token',
  path = process.env.REFRESH_TOKEN_URL ?? 'http://127.0.0.1:4001/refresh-token',
) {
  const refreshTokenRes: { access_token: string; refresh_token: string } =
    await fetch(path, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        refreshToken,
      }),
    }).then((res) => res.json());

  return refreshTokenRes;
}

export function assignTokens(
  accessToken: string,
  refreshToken: string,
  context: Context,
) {
  context.cookies.set({
    name: TOKENS.refreshToken,
    value: refreshToken,
    secure: true,
    httpOnly: true,
    sameSite: 'lax',
    maxAge: COOKIE_AGE.refreshToken,
  });
  context.cookies.set({
    name: TOKENS.accessToken,
    sameSite: 'lax',
    value: accessToken,
    maxAge: COOKIE_AGE.accessToken,
  });
}

export function extractRefreshTokenFromHeaders(
  input: string,
  tokenKind: (typeof TOKENS)[keyof typeof TOKENS],
) {
  let extractedToken;
  const regexPattern = `${tokenKind}=([^;]+)`;
  const regex = new RegExp(regexPattern);
  const match = input.match(regex);

  if (match && match[1]) {
    extractedToken = match[1];
  }
  return extractedToken;
}
